import React, { useState, useMemo, useCallback, useEffect, useContext } from 'react';
import PaymentCardInput from './PaymentCardInput';
// packages
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, FieldValues } from "react-hook-form";
import { toast } from 'react-toastify';
// types
import { GetLinePayPrime, ApplicationType } from 'types';
import { GetApplePayPrime } from 'types/Tappay';
// constants, utils, contexts
import { PaymentGateway, typeMapping } from 'constants/steps';
import { emailRules, phoneRules } from 'constants/index';
import { confirmPaymentAPI, checkPaymentStatusAPI } from 'utils/fetchAPI';
import { GlobalContext } from 'contexts/GlobalContext';
// images
import { ReactComponent as DirectPayIcon } from '../images/direct-pay.svg';
import { ReactComponent as LinePayIcon } from '../images/line-pay.svg';
import { ReactComponent as ApplePayIcon } from '../images/apple-pay.svg';
import { ReactComponent as TaiwanPayIcon } from '../images/taiwan-pay.svg';
// components
import Button from 'components/Button';
import InputField from 'components/InputField';
import Inform from 'components/Inform';
import Radio from 'components/Radio';
import { Option } from '../components/ReceiptSelectInput';
import ReceiptSelectInput from 'components/ReceiptSelectInput';

const SERVER_TYPE = process.env.REACT_APP_SERVER_TYPE;
const SHOW_LINE_PAY = process.env.REACT_APP_SHOW_LINE_PAY;
const ICON_TYPE = {
  [PaymentGateway.DirectPay]: <DirectPayIcon />,
  [PaymentGateway.LinePay]: <LinePayIcon />,
  [PaymentGateway.ApplePay]: <ApplePayIcon />,
  [PaymentGateway.TaiwanPay]: <TaiwanPayIcon />,
}

export interface HolderInfo {
  phone_number: string,
  name: string,
  email: string,
  national_id: string,
  address?: string,
  tax_id?: string,
  zip_code?: string,
  donate_id?: string,
  carrier_id?: string,
  'donate-25885'?: string,
  'donate-919'?: string,
  'donate-8585'?: string,
}

const SectionTitle = (
  { sectionNumber, children, className }:
    { sectionNumber: number, children: JSX.Element | JSX.Element[], className?: string }) => {
  return <div className={`mb-8 ${className}`}>
    <div className="rounded-full mr-1 bg-primary w-5 h-5
      text-white  inline-flex items-center justify-center"> {sectionNumber} </div>
    <h2 className='inline-block'> {children} </h2>
  </div>
}

const SelectPayment = () => {
  const { billDetail, setIsLoading, parkingInformation, isAppleDevice, setIsAppleDevice, removeBillDetail } = useContext(GlobalContext);
  const [paymentMethod, setPaymentMethod] = useState<string>(PaymentGateway.DirectPay);
  const [reuseCheck, setReuseCheck] = useState<boolean>(false);
  const [checkPayment, setCheckPayment] = useState<boolean>(false);
  const [selectedReceiptOption, setSelectedReceiptOption] = useState<Option>({
    key: '',
    title: '',
    inputLabel: '',
    value: ''
  });
  const { type = ApplicationType.Pay } = useParams();
  const navigate = useNavigate();
  const [holderInfo, setHolderInfo] = useState<HolderInfo | FieldValues>({
    address: '',
    email: '',
    name: '',
    national_id: '',
    phone_number: '',
    tax_id: '',
    zip_code: '',
    carrier_id: '',
    donate_id: '',
  });
  const [readyToPayByCard, setReadyToPayByCard] = useState(false);
  const { watch, register, setValue, setError, getValues, handleSubmit, formState: { errors }, clearErrors } = useForm();

  const lot_code = localStorage.getItem('lot_code') as string;
  const area = type === ApplicationType.City ?
    parkingInformation?.area_name : parkingInformation?.lot_name;

  const goToBack = () => {
    if (type === ApplicationType.City) {
      navigate(-1);
    } else {
      // navigate(`/${type}${typeMapping[type][0].path}/${type === ApplicationType.Pay ? billDetail.bill_lot_code : billDetail.operator_code}`);
      navigate(`/${type}${typeMapping[type][0].path}/${lot_code}`);
    }
  };

  // 重新整理導回首頁
  useEffect(() => {
    if (billDetail.bill_amount === 0 || !area) {
      goToBack();
    }
    // eslint-disable-next-line
  }, [billDetail.bill_amount, area]);

  // 檢查是否已付款
  const checkPaymentStatus = async () => {
    try {
      setCheckPayment(true);

      const response = await checkPaymentStatusAPI({
        bill_id: billDetail.bill_id,
        is_payment_check: 1,
      });
      const responseData = await response.json();

      if (!response.ok) {
        console.log('查詢付款狀態失敗', responseData);
        toast.error(responseData);
        removeBillDetail();
        goToBack();
        return false;
      }

      // 0:已付款 1:未付款
      if (responseData.payment_status === 1) {
        console.log('繼續付款');
      } else {
        toast.error('付款失敗：目前停車單已繳費，請重新查詢，避免重複繳費。');
        removeBillDetail();
        goToBack();
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error('伺服器錯誤');
      removeBillDetail();
      goToBack();
    } finally {
      setCheckPayment(false);
    }
  }

  useEffect(() => {
    if (billDetail.bill_id) {
      checkPaymentStatus();
    }
    // eslint-disable-next-line
  }, [billDetail.bill_id]);

  // TapPay setupSDK
  useEffect(() => {
    window.TPDirect?.setupSDK(parkingInformation.app_id, parkingInformation.app_key, `${SERVER_TYPE}`);
  }, [parkingInformation.app_id, parkingInformation.app_key]);

  // Apple pay 前置確認
  useEffect(() => {
    const data = {
      supportedNetworks: ['MASTERCARD', 'VISA', 'AMEX'],
      supportedMethods: ['apple_pay'],
      displayItems: [
        {
          label: (parkingInformation.lot_name || parkingInformation.area_name),
          amount: {
            currency: 'TWD',
            value: billDetail.bill_amount,
          },
        },
      ],
      total: {
        label: (parkingInformation.lot_name || parkingInformation.area_name),
        amount: {
          currency: 'TWD',
          value: billDetail.bill_amount,
        },
      },
    };

    if (window.TPDirect?.paymentRequestApi.checkAvailability()) {
      window.TPDirect?.paymentRequestApi.setupApplePay({
        merchantIdentifier: parkingInformation.merchant_identifier,
        countryCode: 'TW',
      });

      window.TPDirect?.paymentRequestApi.setupPaymentRequest(data, function (result: any) {
        if (result.canMakePaymentWithActiveCard) {
          console.log('裝置可以使用 PaymentRequest / Apple Pay')
        } else {
          console.log('裝置支援 PaymentRequest / Apple Pay，但是沒有可以支付的卡片')
        }
      });
    } else {
      console.log('裝置不支援 PaymentRequest / Apple Pay')
    }
  }, [billDetail.bill_amount, parkingInformation.lot_name, parkingInformation.merchant_identifier, setIsAppleDevice, parkingInformation.area_name]);

  // 選擇付款別，對應後續處理方式 (信用卡, LinePay, ApplePay)
  const triggerPayment = useCallback(async (holderInfo: HolderInfo) => {

    switch (paymentMethod) {
      case PaymentGateway.DirectPay:
        setReadyToPayByCard(true);
        break;

      case PaymentGateway.LinePay:
        window.TPDirect.linePay.getPrime(async function (result) {

          if (result && result.prime) {

            const {
              phone_number,
              name,
              email,
              national_id,
              zip_code = '',
              address = '',
              carrier_id = '',
              donate_id = '',
              tax_id = '',
            } = holderInfo;

            const {
              bill_id = '',
              bill_token = ''
            } = billDetail;

            let paymentDetailTemp = {
              prime: result.prime,
              cardholder: {
                phone_number,
                name,
                email,
                zip_code,
                address,
                national_id
              },
              bill_id,
              payment_method: paymentMethod,
              bill_token,
              carrier_id,
              donate_id,
              tax_id,
              is_reuse: reuseCheck
            };

            try {
              setIsLoading(true);
              const response = await confirmPaymentAPI(paymentDetailTemp);
              const result = await response.json();

              if (result?.payment_url) {
                window.location.replace(result.payment_url);
              }
            } catch (err) {
              toast.error(`err : ${err}`);
            } finally {
              setIsLoading(false);
            }

          } else {
            toast.error(`伺服器錯誤`);
            setIsLoading(false);
          }

        } as GetLinePayPrime);
        break;

      case PaymentGateway.ApplePay:
        window.TPDirect.paymentRequestApi.getPrime(async function (result: any) {

          if (result && result.prime) {

            const {
              phone_number,
              name,
              email,
              national_id,
              zip_code = '',
              address = '',
              carrier_id = '',
              donate_id = '',
              tax_id = '',
            } = holderInfo;

            const {
              bill_id = '',
              bill_token = ''
            } = billDetail;

            let paymentDetailTemp = {
              prime: result.prime,
              cardholder: {
                phone_number,
                name,
                email,
                zip_code,
                address,
                national_id
              },
              bill_id,
              payment_method: paymentMethod,
              bill_token,
              carrier_id,
              donate_id,
              tax_id,
              is_reuse: reuseCheck
            };

            try {
              setIsLoading(true);
              // fetch API
              const response = await confirmPaymentAPI(paymentDetailTemp);

              // Apple Pay不會回傳url，以網頁狀態判斷成功失敗(200/500)
              if (response.status === 200) {
                navigate(`/${type}/success`);
              } else {
                navigate(`/${type}/failed`);
              }
            } catch (err) {
              toast.error(`err : ${err}`);
            } finally {
              setIsLoading(false);
            }

          } else {
            toast.error(`伺服器錯誤`);
            setIsLoading(false);
          }

        } as GetApplePayPrime);

        break;

      case PaymentGateway.TaiwanPay:
        // 台灣pay不需要prime
        (async () => {

          const {
            phone_number,
            name,
            email,
            national_id,
            zip_code = '',
            address = '',
            carrier_id = '',
            donate_id = '',
            tax_id = '',
          } = holderInfo;

          const {
            bill_id = '',
            bill_token = ''
          } = billDetail;

          let paymentDetailTemp = {
            // prime: result.prime,
            cardholder: {
              phone_number,
              name,
              email,
              zip_code,
              address,
              national_id
            },
            bill_id,
            payment_method: paymentMethod,
            bill_token,
            carrier_id,
            donate_id,
            tax_id,
            is_reuse: reuseCheck
          };

          try {
            setIsLoading(true);
            const response = await confirmPaymentAPI(paymentDetailTemp);
            const result = await response.json();

            if (result?.payment_url) {
              window.location.replace(result.payment_url);
            }
          } catch (err) {
            toast.error(`err : ${err}`);
          } finally {
            setIsLoading(false);
          }
        })();

        break;
    }
    // eslint-disable-next-line
  }, [type, paymentMethod, billDetail, reuseCheck, setIsLoading, navigate]);

  const commonInfo = billDetail.common_payment_info;
  const [receiptDefault, setReceiptDefault] = useState('')

  useEffect(() => {
    if (commonInfo && Object.keys(commonInfo).length > 0) {
      switch (commonInfo.donate_id) {
        case '25885':
          setReceiptDefault("donate-25885")
          break;
        case '919':
          setReceiptDefault("donate-919")
          break;
        case '8585':
          setReceiptDefault("donate-8585")
          break;
        case "":
          setReceiptDefault("carrier_id")
          break;
      }
    } else {
      setReceiptDefault("")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = useCallback((data: HolderInfo | FieldValues) => {
    const {
      address,
      email,
      name,
      national_id,
      phone_number,
      tax_id,
      carrier_id,
      zip_code,

    } = data;

    let donate_id = ''

    if (
      !!billDetail?.bill_id &&
      !tax_id &&
      !carrier_id &&
      !Object.keys(data).includes('donate-25885') &&
      !Object.keys(data).includes('donate-919') &&
      !Object.keys(data).includes('donate-8585')
    ) {
      setError('receiptMethodIsRequire', { type: 'receiptMethod', message: '請填入至少一種電子發票存入方式' });
      return;
    }

    if (!!data?.['donate-25885']) {
      donate_id = '25885';
    } else if (!!data?.['donate-919']) {
      donate_id = '919';
    } else if (!!data?.['donate-8585']) {
      donate_id = '8585';
    }

    const holderInfo = {
      phone_number,
      name,
      email,
      zip_code,
      address,
      national_id,
      carrier_id,
      tax_id,
      ...(donate_id && { donate_id })
    };
    setHolderInfo(holderInfo);

    triggerPayment(holderInfo);
  }, [billDetail?.bill_id, triggerPayment, setError]);

  const topError = useMemo(() => {
    const errorObject = Object.values(errors)[0] || { message: '' };
    return errorObject.message;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, Object.keys(errors).length]);

  const onPaymentMethodChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentMethod(e.target.value);
  };
  const hasError = Object.keys(errors).length > 0;


  return <div className='p-10 pb-12 px-5 md:px-20'>
    {
      (readyToPayByCard && !hasError) ?
        <div className='flex w-full flex-col pb-8 max-w-[320px] md:max-w-[658px]  mx-auto items-center'>
          <PaymentCardInput
            holderInfo={holderInfo}
            paymentMethod={paymentMethod}
            reuseCheck={reuseCheck}
            goToBack={() => setReadyToPayByCard(false)}
          />
        </div> :
        <>
          <div className="flex w-full flex-col pb-8 md:max-w-[658px] mx-auto items-center md:flex-row md:items-start ">
            <div className="flex-1  mb-6 mr-0 md:mb-0 md:mr-12 max-w-[260px]">
              <SectionTitle className="pl-0 md:pl-4" sectionNumber={1}  >
                <span className='leading-5'>請填寫付款人資料</span>
                <span className='text-error text-sm'> ＊為必填資料</span>
              </SectionTitle>
              <form >
                <InputField
                  {...register("phone_number", {
                    setValueAs: value => value.trim(),
                    required: '手機號碼為必填',
                    pattern: {
                      value: phoneRules,
                      message: '手機號碼格式錯誤'
                    },
                    minLength: {
                      value: 10,
                      message: '手機號碼長度為10碼'
                    },
                    maxLength: {
                      value: 10,
                      message: '手機號碼長度為10碼'
                    }
                  })}

                  defaultValue={(commonInfo && Object.keys(commonInfo).length > 0) ? commonInfo.phone_number : ""}
                  required
                  label='手機號碼'
                  placeholder='請輸入手機號碼共10碼'
                  className={`mb-4`}
                  error={errors.phone_number}
                />

                <InputField
                  {...register("name", { required: '持卡人姓名為必填', setValueAs: value => value.trim() })}
                  defaultValue={(commonInfo && Object.keys(commonInfo).length > 0) ? commonInfo.name : ""}
                  required
                  label='持卡人姓名'
                  placeholder='請輸入持卡人姓名'
                  className='mb-4'
                  error={errors.name}
                />

                <InputField
                  {...register('email', {
                    required: '電子信箱為必填',
                    setValueAs: value => value.trim(),
                    pattern: {
                      value: emailRules,
                      message: '電子信箱格式錯誤'
                    }
                  })}
                  defaultValue={(commonInfo && Object.keys(commonInfo).length > 0) ? commonInfo.email : ""}
                  required
                  label='電子信箱'
                  placeholder='請輸入電子信箱'
                  className='mb-4'
                  error={errors.email}
                />

                {/* <InputField
                  {...register('national_id', {
                    required: '身分證字號為必填',
                    pattern: {
                      value: nationIdRules,
                      message: '身分證字號格式錯誤'
                    }
                  })}
                  required
                  label='身分證字號'
                  placeholder='請輸入身分證字號'
                  className='mb-4'
                  error={errors.national_id} />

                <InputField
                  {...register('zip_code')}
                  label='郵遞區號'
                  placeholder='請輸入郵遞區號'
                  className='mb-4'
                />

                <InputField
                  {...register('address')}
                  label='地址'
                  placeholder='請輸入地址'
                  className='mb-4' /> */}

                <div className='flex items-center'>
                  <span className='text-error w-5 h-5 flex items-center justify-center mr-1 text-[12px]'>
                    ＊
                  </span>
                  <ReceiptSelectInput
                    setValue={setValue}
                    watch={watch}
                    getValues={getValues}
                    register={register}
                    clearErrors={clearErrors}
                    errors={errors}
                    carrer_id_number={commonInfo.carrier_id}
                    defaultValue={(commonInfo && Object.keys(commonInfo).length > 0) ? receiptDefault : selectedReceiptOption.key}
                    onChange={setSelectedReceiptOption}
                  />
                </div>
                {selectedReceiptOption?.title === '手機條碼載具' &&
                  <div className='text-xs	text-red-600 mt-2 ml-6 tracking-tight'>
                    請確認您輸入的載具資料是否正確，確認後無法再修改
                  </div>}
              </form>
            </div>

            <div className="flex-1">
              <SectionTitle sectionNumber={2}   >
                <span className='leading-5'>請選擇付款方式</span>
              </SectionTitle>
              <div onChange={onPaymentMethodChange}>
                <div className='flex items-center mb-5'>
                  <Radio
                    id="credit_card"
                    value={PaymentGateway.DirectPay}
                    name="paymentType"
                    title={'信用卡'}
                    defaultChecked />
                  {ICON_TYPE[PaymentGateway.DirectPay]}
                </div>

                {SHOW_LINE_PAY === 'true' && <div className='flex items-center mb-5'>
                  <Radio
                    id="line_pay"
                    value={PaymentGateway.LinePay}
                    name="paymentType"
                    title={'LINE Pay'} />
                  {ICON_TYPE[PaymentGateway.LinePay]}
                </div>}
                {/* 後端API此欄位為1(true)時, 顯示ApplePay選項 */}
                {(parkingInformation.is_apple_pay === 1 && isAppleDevice) && <div className='flex items-center mb-5'>
                  <Radio
                    id="apple_pay"
                    value={PaymentGateway.ApplePay}
                    name="paymentType"
                    title={'Apple Pay'} />
                  {ICON_TYPE[PaymentGateway.ApplePay]}
                </div>}
                {/* {parkingInformation.is_taiwan_pay === 1 &&
                  <div className='flex items-center mb-5'>
                    <Radio
                      id="taiwan_pay"
                      value={PaymentGateway.TaiwanPay}
                      name="paymentType"
                      title={'台灣Pay'} />
                    {ICON_TYPE[PaymentGateway.TaiwanPay]}
                  </div>
                } */}
              </div>
            </div>
          </div>
          <div className=' flex justify-center mb-2'>
            <Inform id="is_reuse"
              value={reuseCheck}
              name='is_reuse'
              className='font-semibold'
              onChange={(e) => setReuseCheck(e.target.checked)}
              title={'加入常用付款資料'} />
          </div>
          {/* 更改樣式 */}

          {!!topError && <div className='max-full mx-auto my-2 text-center text-[12px] text-error'> {topError + ''} </div>}
          <div className='relative lg:w-3/12 w-4/5 md:w-6/12 sm:w-2/4 m-auto mb-12 mt-ˋ'>
            {checkPayment ?
              <div className='max-full mx-auto my-2 text-center text-[16px] font-bold text-primary'>資料確認中...</div>
              :
              <Button type="submit" className=" mx-auto block" title="下一步"
                onClick={handleSubmit(onSubmit)} disabled={!!hasError}
              />}
            <div className='md:max-w-[658px] max-w-[320px] mx-auto absolute top-1/2 left-0' style={{ transform: "translateY(-50%)" }}>
              <i className="fa-solid fa-chevron-left cursor-pointer text-primary" onClick={goToBack} ></i>
            </div>
          </div>
        </>
    }
  </div>;
}
export default SelectPayment;